.projectPhoto{
    width: 90%;
    height: 250px; 
    object-fit: cover;
    border-radius: 15px;
    margin: 1em;
}

.contactButtons {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.projectHeader {
    /* border-radius: 15px;
    border-style: solid;
    border-color: var(--outline);
    display: flex;
    flex-direction: row;
    padding: 40px;
    margin-top: 20px; */
    position: relative;
    width: 96vw;  
    margin-left: calc(-48vw + 50%); 
    text-align: center;
}

.contactColumn button {
    color: white;
    background-color: var(--accentColor);
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    font-size: 1em;
}

.nameColumn {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 80%;
}

.contactColumn {
    display: flex;
    gap: 1em;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;
}

.infoSection{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: space-between; /* Name on left, contact on right */
    align-items: center; /* Align items vertically */
    width: 100%; /* Ensure full width */
}

.h1 {
    font-size: 48px;
    text-align: left;
    color: var(--boldtext);
    line-height: 50px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.h2, .h3 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--boldtext);
}

h4 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(boldtext);
}

.status {
    border: solid;
    border-width: 1px;
    border-color: gray;
    border-radius: 3px;
    background-color: var(--background);
    padding: 10px;
    width: fit-content;
    text-wrap: nowrap;
}

.projectPageContent {
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.adminButtons {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.memberCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
}

.memberCard {
    width: 17em;
    border: solid;
    border-width: 2px;
    border-color: var(--outline);
    border-radius: 5px;
    padding: 16px;
}

.memberCard p {
    color: var(--boldtext);
}

.memberCard a {
    font-weight: bold;
    font-size: 1.17em;
    color: var(--boldtext);
    text-decoration: none;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.memberCard a:hover {
    color: var(--themeColor);
}

.memberCardInfo {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 0.7em;
}

.memberDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px;
    text-wrap: nowrap;
}

.memberDetails > p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.profilePicture {
    width: 40px;
    height: 40px;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.tag {
    color:white;
    background-color: var(--themeColor);
    border-radius: 15px;
    padding: 0.4em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    width: fit-content;
}

.aboutSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.aboutSection h3 {
    margin-top: 0;
}

.aboutParagraph {
    margin-right: 2.5em;
}

.joinRequests {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.joinRequests button {
    background-color: var(--gray);
    color: var(--text);
    border: none;
    border-radius: 5px;
    max-height: 3em;
}

.contactColumn button.joinButton{
    background-color: var(--accentColor);
    cursor: pointer;
    white-space: nowrap;
}
.contactColumn button.joinButton:enabled:hover {
    background-color: var(--accentColorDark);
}

.contactColumn button.joinButton.joined {
    background-color: #41a838;
    cursor: default;
}

.contactColumn button.joinButton.joined:hover {
    background-color: var(--red);
    cursor: pointer;
}

.nameColumnLower {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.editButton {
    background-color: var(--accentColor);
    cursor: pointer;
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    font-size: 1em;
    color: white;
    text-wrap: nowrap;
}

.editButton:hover {
    background-color: var(--accentColorDark);
    cursor: pointer;
}

.popup {
    border-radius: 5px;
    padding: 3em;
    border-style: solid;
    border-color: gray;
    background: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.popup p {
    margin-top: 0;
}

.popupTextInput {
    width: 95%;
    height: 4rem;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 0.5em;
    outline: none;
}

.sendRequestButton {
    background-color: var(--themeColor);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    padding: 10px;
    box-sizing: content-box;
    border-style: none;
    font-size: 15px;
    margin-top: 1em;
}

.requestCard {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

/* Gallery Styles */
.galleryContainer {
    margin: 2rem 0;
    /* overflow-x: scroll; */
    /* display: flex;
    flex-direction: row; */
  }
  
  .galleryGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .galleryItem {
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease;
  }
  
  .galleryItem:hover {
    transform: scale(1.03);
  }
  
  .galleryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

@media screen and (max-width: 600px) {
    .contactColumn {
        text-align: left;
        flex-direction: row;
    }
    .projectPhoto {
        width: 95vw;
        margin-left: 0;
    }

    .nameColumn {
        max-width: 100%;
    }
}