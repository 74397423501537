.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: auto;
    overflow: hidden;
    margin-bottom: 2em;
}

.container input {
    background-color: white;
    color: black;
}

.background{
    position: fixed; /* position will be in relation to viewport */
    top: 0; /* setting position to top left of viewport */
    left: 0;
    width: 100vw; /* sized to dimensions of viewport */
    height: 100vh;
    background-image: url('../../assets/students.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: rgba(255, 0, 0, 0.25);
    z-index: -1; /* this forces the div to the background */
}

.entryArea{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--red);
    border-radius: 25px;
    padding: 50px 25px 50px 25px;
    width: 600px;
    max-width: 80vw;
}

.inputTable{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
}

.inputTableBody{
    width: 100%;
}

.recoveryLink{
    color: white;
    text-decoration: underline;
}

.formTitle{
    color: white;
    justify-content: center;
    font-size: 250%;
    margin: 0px 0px 5px 0px;
}

.columnTr{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.fieldLabel{
    color: white;
    justify-content: left;
    font-size: 150%;
    font-weight: bold;
}

.formInput{
    border: 0px;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    font-size: 16px;
}

.rowTd {
    display: flex;
    align-items: center;
    width: 100%;
}

.rowTd input {
    flex-grow: 1;
    margin-right: 5px;
    width: 100%;
    font-size: 16px;
}

.rowTd button {
    flex-shrink: 0;
    cursor: pointer;
}

.redirectLink{
    color: white;
    text-decoration: underline;
    font-weight: bold;
}

.submitButton{
    font-family: 'Roboto', 'sans-serif';
    font-weight: bolder;
    font-size: 125%;
    color:white;
    background-color: black;
    border: 0px;
    border-radius: 5px;
    height: 100%;
    padding: 10px;
    cursor: pointer;
}

.footer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.logoImage{
    width: 20vw;
    height: 20vw;
    margin: 2em;
}

.container p {
    font-size: 125%;
    color: white;
}

.codeInputs {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.codeInputs input {
    font-family: 'Roboto', 'sans-serif';
    width: 30px;
    height: 45px;
    border: 0px;
    border-radius: 5px;
    padding: 5px;
    font-size: 15px;
    text-align: center;
}

.sendCodeButton {
    font-family: 'Roboto', 'sans-serif';
    font-weight: bolder;
    font-size: 100%;
    color: white;
    background-color: #3dc45a;
    border: 0px;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.sendCodeButton:disabled {
    cursor: default;
    opacity: 70%;
}