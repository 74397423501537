.container{
    margin: 0px 150px 25px 150px;
}

@media screen and (max-width: 900px) {
    .container {
        margin: 0;
    }
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: .25rem solid var(--red);
}

.formBody {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    gap: 2rem;
}

.fieldLabel {
    display: flex;
    font-size: 120%;
    justify-content: space-between;
    /* gap: 5px; */
}

.entryField {
    display: flex;
    flex-direction: column;
    border: 2px solid lightgrey;
    border-radius: 20px;
    padding: 20px;
}

.wideTextEntry, .largeTextEntry .smallTextInput {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    border: 2px solid gray;
    padding: 5px;
}

.wideTextEntry, .smallTextInput {
    border-radius: 20px;
}

.largeTextEntry {
    font-family: 'Roboto', sans-serif;
    font-size: 85%;
    width: 100%;
    height: 100px;
    resize: vertical;
    box-sizing: border-box;
    border-radius: 10px;
}

.hspanElements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.createButton{
    justify-content: center;
    width: 50%;
    background-color: var(--themeColor);
    color: white;
    border-radius: 10px;
    border: 0px;
    font-size: 150%;
    font-weight: bold;
    cursor: pointer;
    text-wrap: nowrap;
    min-width: fit-content;
}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
}