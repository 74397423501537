.helpContainer {
    position: relative;
    display: inline-block;
}

.questionMarkCircle {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #e6e6e6;
    color: black;
    font-size: 12px;
    cursor: pointer;
}

.helpMessage {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--gray);
    color: var(--text);
    padding: 8px 12px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    white-space: normal;
    max-width: 200px;
    word-wrap: break-word;
    font-size: 14px;
    width: 200px;
    text-align: center;
    z-index: 1000;
}