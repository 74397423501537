/* src/styles/TopNav.css */
  .NavBar {
    position: sticky;
    top: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: var(--background);
    padding: 20px;
    padding-left: 0;
    z-index: 1000;
    border-bottom: solid var(--outline) 2px;
  }

  .NavBar svg {
    color: var(--icon);
  }

  .NavBar svg:hover {
    color: var(--themeColor);
  }

  .NavBar a {
    color: var(--boldtext);
  }

  .NavBar a:hover {
    color: var(--themeColor);
  }

  .LogoLink {
    margin-right: auto;
  }


  .NavItemsWide {
    display: flex;
    flex-direction: row;
    width: 80vw;
    display: flex;
    justify-content: end;
    gap: 1.5em;
    align-items: center;
    font-size: 24;
  }

  .LogoLink img {
    /* border-radius: 50%; */
  }

  .CreateProjectButton{
    text-decoration: none;
    font-weight: bold;
    color: white;
    background-color: var(--themeColor);
    padding: 7px;
    border-radius: 7px;
}

  .CreateProjectButton:hover {
    background-color: black;
    color:white;
  }

  .NavLink{
    color: var(--boldtext);
    font-weight: bold;
  }

  .NavLink:link {
    text-decoration: none;
  }

  .NavLink:hover {
    text-decoration: none;
    color: var(--themeColor);
  }

.searchForm{
    flex-grow: 1;
}

.searchBar{
    border-radius: 25px; /* Rounded corners */
    background-color: var(--background); /* Light gray background */
    outline: none; /* Removes focus outline */
    transition: box-shadow 0.3s ease; /* Smooth effect for focus */
    box-sizing: border-box; /* Ensures padding is included in width */
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    border: solid var(--themeColor) 2px;
}

.searchInput{
    flex-grow: 1;
    font-size: 16px;
    outline: none;
    border: none;
    padding: 10px 0px 10px 20px;
    border-radius: 25px;
    background-color: var(--background);
}

.searchLink {
    margin-right: 5px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: var(--accentColor);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.searchType{
  border:none;
  padding: 10px;
  border-radius: 7px;
  background-color: #2C2C2C;
  color:white;
  font-size: 16px;
}

.profileSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.userImage {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.logoutButton{
    height: 30px;
    width: 30px;
    object-fit: cover;
    cursor: pointer;
}

/* The dropdown container */
.dropdown {
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdownContent {
  position: absolute;
  right: 11vw;
  display: none;
  background-color: var(--background);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdownContent a:hover {
  background-color: #ddd;
}

.dropdownContent a {
  float: none;
  color: var(--boldtext);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.NavItemsNarrow {
    display: none;
}

@media screen and (max-width: 900px) {
  .NavItemsWide {
    display: none;
  }
  .NavItemsNarrow {
    display: block;
    width: 95vw;
  }
  .VisibleContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
  .menuButton {
    float: right;
    padding-top: 5px;
    display: block;
  }
}
