footer p {
    color: var(--text);
    font-size: 8pt;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* width: 100vw; */
    margin-top: auto;
    padding-bottom: 1em;
    background-color: transparent;
    /* border-top: solid var(--gray) 2px; */
    position: relative;
    /* width: 100%; */
    bottom: 0;
    padding-top: 2em;
}

.footerContent {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 900px) {
    .footerContent {
        width: 95vw;
    }
}