

/* .imageWrapper {
    position: relative;
    width: 160px;
    height: 120px;
} */

/* ✅ Initially hide the image until it fully loads */
/* .hidden {
    opacity: 0;
}


.skeleton{
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text{
    width:100%;
    height: .5rem;
}

@keyframes skeleton-loading {
    0%{
        background-color: hsl(200, 20%, 70%);
    }

    0%{
        background-color: hsl(200, 20%, 95%);
    }
} */

/* Form Styling */
.searchForm {
    display: flex;
    flex-direction: row;
    gap: 3em;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
    /* padding-top: 1em; */
    padding-bottom: 1em;
    position: sticky;
    /* top: 66px; */
    align-self: start;
    height: auto;
    background-color: var(--background);
}

.searchForm > label {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 0.4em;
}

/* Add focus effect */
.query:focus {
    background-color: #FFFFFF; /* Slightly darker background on focus */
    box-shadow: 0 0 5px gray; /* Subtle blue glow */
}

.searchResult {
    scroll-margin-top: 8em;
    scroll-snap-margin-top: 8em;
    border-style: solid;
    border-radius: 10px;
    border-color: var(--outline);
    border-width: 2px;
    margin-bottom: 1em;
    padding: 1.5em;
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
}
.status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}
.detailsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--text);
    gap: 0.5em;
    flex-wrap: wrap;
    width: 100%;
}

.detailsRow span {
    text-wrap: nowrap;
}

.detailsRow div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.searchResult h1 {
    margin-bottom: 0.3em;
    margin-top: 0em;
}

.searchResult p {
    color: var(--text);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-size: 100%;
    line-height: 1.4;
}

.searchResult p>a:hover {
    color: var(--text);
}

.textAndImage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
}

.projectImage {
    height: 120px;
    width: 160px;
    align-self: center;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 1.5em;
}

.userImage {
    height: 120px;
    width: 120px;
    min-width: 120px;
    object-fit: cover;
    margin-right: 1.5em;
    border-radius: 50%;
}

.previewText {
    max-width: 65%;
}

.previewText a {
    color: var(--boldtext);
    text-decoration: none;
}

.previewText a:hover {
    color:var(--themeColor);
}

.tags {
    display: flex;
    flex-direct: row;
    flex-wrap: wrap;
    margin-top: 1em;
    gap:10px;
}

.tag {
    color:white;
    background-color: var(--themeColor);
    border-radius: 15px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: fit-content;
    white-space: nowrap;
}

.filterMenu {
    border-style: solid;
    border-radius: 10px;
    border-color: var(--outline);
    border-width: 2px;
    margin-bottom: 1em;
    padding: 1.5em;
}

.filterMenu > form {
    display: flex;
    flex-direction: column;
    gap: 3em;
}

.filterMenu h3 {
   margin-top: 0;
}

.tagInput label {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.projectStatus {
    display: flex;
    flex-direction: column;
}

.checkboxInput {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.checkboxInput > input {
    accent-color:var(--themeColor);
}

.minMaxInput {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.numberInput {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
}

.numberInput input {
    font-size: 14px;
    border-radius: 15px;
    border: 2px solid #ccc; /* Light gray border */
    border-radius: 20px;
    background-color: var(--background); /* Match gray color */
    color: var(--text); /* Darker text */
    outline: none;
    padding: 5px;
}


/*
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
*/

@media screen and (max-width: 1260px) {
    .filterMenu {
        width: 75vw;
    }
    .filterContents {
        width: 100%;
        gap: 1em;
    }
}

@media screen and (max-width: 900px) {
    .filterMenu, .searchResult {
        width: calc(94vw - 3em);
        max-width: calc(94vw - 3em);
    }

}

@media screen and (max-width: 800px) {
    .results {
        min-width: 90vw;
        max-width: 90vw;
    }
    .textAndImage {
        flex-direction: column;
        align-items: start;
    }
    .projectImage, .userImage {
        display: none;
    }
    .searchResult p {
        text-wrap: wrap;
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
}
@media screen and (max-width: 500px) {
    .searchResultText {
        margin-left: 0px;
    }
}

.container {
    display: flex; /* Use Flexbox */
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px; /* Add space between filter and results */
  }
  
  .filter {
    flex: 0 0 15rem;  /*Fixed width for the filter */
  }
  
  .results {
    min-width: 730px;
    flex: 1; /* Take up remaining space */
  }


.filterContents {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.filterContents>div {
    max-width: 15rem;
}

/* Flex container */
.buttonContainer {
    display: flex;
    gap: 10px;
    margin-right: 3em;
}

.searchFormDisplay {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.toggleLabel {
    color: var(--boldtext);
}

/* Default button styling */
.toggleButton {
    padding: 5px 16px;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid var(--themeColor);
    background-color: var(--background);
    color: var(--themeColor);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* Active button (Selected) */
.toggleButton.active {
    background-color: var(--themeColor);
    color: white;
}

/* Hover effect */
.toggleButton:hover {
    background-color: var(--themeColor);
    color: white;
}

/* Prevent clicks from reloading same data */
.toggleButton.active:hover {
    cursor: default;
    background-color: var(--themeColor);
}

.removeButton{
    border:none;
    background-color: transparent;
    color:white;
}

.searchResultText{
    margin-top: 0px;
    margin-bottom: 0px;
}

.previewMessage {
    margin-left: auto;
    align-self: start;
}



/* Toggle Switch Container */
.toggleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Switch Wrapper */
.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
}

/* Hide Default Checkbox */
.toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Toggle Track (Background) */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* OFF state (gray) */
    border-radius: 34px;
    transition: background-color 0.3s ease;
}

/* Circular Knob */
.slider:before {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: var(--background);
    border-radius: 50%;
    transition: transform 0.3s ease;
}

/* Turn ON State (Blue Background) */
input:checked + .slider {
    background-color: var(--themeColor);
}

/* Move Knob to Right When ON */
input:checked + .slider:before {
    transform: translateX(22px);
}


