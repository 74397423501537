@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,800;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Passion+One&family=Plus+Jakarta+Sans:ital,wght@0,800;1,800&display=swap');


/* Default: Light Theme */
:root {
    --gray: #EDEDED;
    --themeColor: #CC3433;
    --accentColor: #595959;
    --accentColorDark: #0056b3;
    --red: #CC3433;
    --redDark: #832121;
    --outline: #CCCCCC;
    --green: #3dc45a;
    --background: #FFFFFF;
    --text: #595959;
    --boldtext: #000000;
    --gradientTop: #CC3433;
    --gradientBottom: rgb(255, 0, 0, 0);
    --icon: #1E1E1E;
}

/* Dark Theme */
.dark-theme {
    --gray: #707070;
    --background: #333;
    --text: #DDDDDD;
    --boldtext: #FFFFFF;
    --outline: #888888;
    --gradientTop: #555;
    --gradientBottom: #333;
    --icon: #DDDDDD;
}

/* System Theme (this will follow the system preference) */
.system-theme {
    --gray: #EDEDED;
    --themeColor: #CC3433;
    --accentColor: #595959;
    --accentColorDark: #0056b3;
    --red: #CC3433;
    --redDark: #832121;
    --outline: #CCCCCC;
    --green: #3dc45a;
    --background: #FFFFFF;
    --text: #595959;
    --boldtext: #000000;
    --gradientTop: #CC3433;
    --gradientBottom: rgb(255, 0, 0, 0);
    --icon: #1E1E1E;
}

/* Apply system color preferences */
@media (prefers-color-scheme: dark) {
    .system-theme {
        --gray: #707070;
        --background: #333;
        --text: #DDDDDD;
        --boldtext: #FFFFFF;
        --outline: #888888;
        --gradientTop: #555;
        --gradientBottom: #333;
        --icon: #DDDDDD;
    }
}

@media (prefers-color-scheme: light) {
    .system-theme {
        --gray: #EDEDED;
        --themeColor: #CC3433;
        --accentColor: #595959;
        --accentColorDark: #0056b3;
        --red: #CC3433;
        --redDark: #832121;
        --outline: #CCCCCC;
        --green: #3dc45a;
        --background: #FFFFFF;
        --text: #595959;
        --boldtext: #000000;
        --gradientTop: #CC3433;
        --gradientBottom: rgb(255, 0, 0, 0);
        --icon: #1E1E1E;
    }
}


main {
    width: 100vw;
    justify-content: center;
    display:flex;
    /* height:100%; */
}

.mainBackground {
    background-color: var(--background);
    z-index: -2;
    position: fixed; /* position will be in relation to viewport */
    top: 0; /* setting position to top left of viewport */
    left: 0;
    width: 100vw; /* sized to dimensions of viewport */
    height: 100vh;
}

textarea, input {
    background-color: var(--background);
    color: var(--text);
}

p{
    font-family: 'Roboto', sans-serif;
    color: var(--text);
}

h1{
    font-family: 'Plus Jakarta Sans', serif;
}

h2, h3, label{
    color: var(--boldtext);
}

title{
    font-family: 'Passion One', Impact;
}

.MainContent {
    width: 80vw;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

body, html {
    overflow-x: hidden;
    max-width: 100%;
}

html {
  scroll-behavior: smooth;
  color: var(--text);
}

small {
    color: var(--boldtext);
}

@media screen and (max-width: 900px) {
    .MainContent {
    width: 95vw;
    }
    .Collapsed {
        display: none;
    }
    .Expanded {
        color: var(--boldtext);
        padding: 12px 16px;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: 1.5em;
    }
}