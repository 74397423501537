.popup {
    border-radius: 5px;
    padding: 2.5em;
    padding-left: 3.5em;
    padding-right: 4em;
    border-style: solid;
    border-color: gray;
    background: var(--background);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.messageButton{
    background-color: var(--accentColor);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    font-size: 1em;
}

.messageButton:hover{
    background-color: var(--accentColorDark);
}

.popupTextInput {
    width: 95%;
    height: 4rem;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 0.5em;
    outline: none;
}

.sendButton {
    background-color: var(--accentColor);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    padding: 10px;
    box-sizing: content-box;
    border-style: none;
    font-size: 15px;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
}

.popup p {
    margin-top: 0;
}