.formButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2em;
}

.formButtons button {
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    flex-grow: 1;
    font-size: 1em;
}

.submitButton {
    background-color: var(--accentColor);
    color: white;
}

.submitButton:hover {
    background-color: var(--accentColorDark);
    cursor: pointer;
}

.cancelButton:hover {
    background-color: var(--red);
    cursor: pointer;
    color: white;
}

.deleteSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2em;
}

.deleteCheck {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5em;
    width: 65%;
    align-items: center;
}

.deleteCheck:hover {
    color: var(--red);
}

.deleteButton {
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    flex-grow: 1;
    font-size: 1em;
    background-color: var(--red);
    color: white;
}

.deleteButton:hover {
    background-color: var(--redDark);
    cursor: pointer;
    color: white;
}

.deleteButton:disabled {
    border-style: solid;
    border-color: var(--red);
    color: var(--red);
    background-color: white;
    cursor: default;
}

.largeTextEntry {
    flex-grow: 1;
    border-radius: 5px;
    max-width: 90vw;
    min-height: 10em;
    padding: 1em;
    font-size: 16px;
}

.aboutSection {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.meetingFormatOptions {
    display: flex;
    flex-direction: row;
    gap: 2em;
}

/* Gallery Styles */
.gallerySection {
    margin: 2rem 0;
  }
  
  
  .galleryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .galleryUpload {
    margin-top: 1rem;
  }
  
  .galleryUploadLabel {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f0f0f0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .galleryUploadLabel:hover {
    background-color: #e0e0e0;
  }
  
  .galleryUploadLabel span {
    pointer-events: none;
  }

  /* Gallery Styles */
.gallerySection {
    margin: 2rem 0;
  }
  
  .galleryUpload {
    margin-top: 1rem;
  }
  
  .galleryUploadLabel {
    display: inline-block;
    padding: 8px 16px;
    background-color: #f0f0f0;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .galleryUploadLabel:hover {
    background-color: #e0e0e0;
  }

  .galleryPreview {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .galleryImageWrapper {
    position: relative;
    width: 230px;
    height: 230px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #eee;
  }
  
  .galleryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .uploadStatus {
    position: absolute;
    bottom: 4px;
    right: 4px;
    background: var(--gray);
    color: black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  
  .removeGalleryButton {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    background: var(--gray);
    color: black;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 0;
  }

.memberCard {
    display: flex;
    flex-direction: row;
    gap: 2em;
    align-items: center;
}

.memberCardInfo {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 0.7em;
}

.tagEntry > input {
    margin-bottom: 1em;
    margin-right: 1em;
    font-size: 16px;
}

.removeButton {
    margin-left: 0.5em;
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1em;
}

.removeButton:hover {
    color: black;
    font-weight: bold;
}
/* 
.imageButtons {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
    width: 4em;
    margin: 1em;
}

.imageButtons button {
    background-color: var(--gray);
    border: none;
    border-radius: 5px;
    padding: 1em;
    color: var(--text);
}

.imageButtons button:hover {
    background-color: var(--accentColor);
    color: white;
} */

.imageButtonWrapper{
    position: absolute;
    top: 17px;
    right: 90px;
    display: flex;
    /* gap: 10px; */
    justify-content: flex-start; /* Or center/right as needed */
    align-items: center;

}

.changeImageButton {
    background-color: rgba(0, 0, 0, 0.432);
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  }
  
.changeImageButton:hover {
background-color: rgba(0, 0, 0, 0.9);
}


.removeButton:disabled {
    background-color: var(--red);
    cursor: default;
}

.removeMemberButton:hover:enabled {
    background-color: var(--red);
    cursor: pointer;
    color: white;
}

.removeMemberButton {
    background-color: var(--gray);
    border: none;
    border-radius: 5px;
    color: var(--text);
    text-wrap: nowrap;
}

.removeMemberButton:disabled {
    color: #999;
}

.projectTitleEntry {
    flex-grow: 1;
    height: 2rem;
    font-size: 125%;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    padding-left: 5px;
}

.nameColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.fieldLabel{
    font-weight: bold;
    font-size: 1.5em;
}

.nameRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
}

.memberCards {
    overflow-x: auto;
}

.memberCards table {
    width: 100%;
}

.memberCards td {
    text-align: left;
    padding: 0.5em;
}

.memberCards th {
    font-size: 24px;
    text-align: left;
    color: var(--boldtext);
}

.dropdown {
    min-width: 10em;
    padding-right: 1em;
}

.editTitle  input {
    font-size: 16px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    padding-left: 2px;
    padding: 2px;
}

.radio {
    margin: 1em;
}

.statusCheck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.deleteSection input[type=checkbox] {
    transform: scale(1.5);
    margin: 0.5em;
    min-width: 15px;
}