.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15vh;
}

.mainContainer h1{
    font-weight: bolder;
    font-size: 700%;
    color: var(--red);
    margin: 0px;
}

.mainContainer a{
    font-weight: bold;
}