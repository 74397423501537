@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');


.Section1{
    position: relative;
    /* height: 200px; */
    width: 100vw !important;
    min-height: 4em;
    max-height: 100%;
    text-wrap: pretty;
    left: 0px;
    right: 0px;
    background-image: linear-gradient(var(--gradientTop) 40%, var(--gradientTop) 50%, var(--gradientBottom) );
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 800px) {
    .previewCard {
        max-width: 70vw;
    }
    .bannerContent {
        width: 95%;
    }
}

.Section1 h1{
    font-family: "Passion One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 9vw;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    text-transform: uppercase;
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
}

.Section2{
    background-color: var(--gradientBottom);
    position: relative;
    /* height: 200px; */
    width: 100vw !important;
    min-height: 100vh;
    left: 0px;
    right: 0px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-wrap: wrap;
}

.bannerContent {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.Section2 h2{
    font-family: "Passion One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 7vw;
    margin-bottom: 0px;
    text-transform: uppercase;
    color:var(--red);
}

.Section2 h3{
    margin-bottom: 2em;
    margin-top: 0;
    font-family: "Roboto", serif;
    font-optical-sizing: auto;
    font-size: 22px;
    color:var(--red);
}

.Section2 button{
    border: none;
    background-color: var(--green);
    border-radius: 5px;
    padding: 10px;
    margin-left: 30px;
    color: white;
    font-family: "Roboto", serif;
    font-weight: bolder;
    font-size: 16px;
    align-self: end;
}

.Section2 a:hover {
    color: white;
}

.topInfo {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-bottom: 1em;
    padding-top: 1.5em;
    padding-left:1.5em;
    padding-right: 1.5em;
}

.previews {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 1.5em;
}

.previewCard {
    width: 20rem;
    min-width: 20rem;
    background-color: var(--background);
    border-radius: 10px;
    border: solid var(--red);
    border-width: 2px;
    margin-bottom: 1em;
    /* padding: 1.5em; */
    display: flex;
    flex-direction: column;
    color: var(--text);
}

.projectImage {
    width: 20rem;
    height: 12em;
    align-self: center;
    object-fit: cover;
    border-radius: 8px 8px 0px 0px;
    /* border-radius: 8px; */
}

.previewCard h3 {
    font-size: 30px;
    font-style: normal;
    margin:2px;
}

.previewCard a:hover {
    color: var(--boldtext);
}

.previewText {
    /* display: flex; */
    /* flex-direction: column; */
    padding-left: 1.5em;
    padding-right: 1.5em;
    /* gap: 0.5em; */
}

/* .previewText div {
    display: flex;
    flex-direction: row;
    align-items: center;
} */

.userImage {
    width: 3em;
    height: 3em;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
}

.bottomText {
    margin: 4em;
    margin-bottom: 6em;
}

.footerContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2em;
}

.status {
    margin-top: 5px;
    font-size: 1em;
    display: flex;
    flex-direction: row;
}

.status > p {
    margin: 0.5em;
    align-self: center;
    font-weight: bold;
    font-size: 1.17em;
    color: var(--boldtext);
}

.status > svg {
    width: 30px;
    height: 24px;
    align-self: center;
    color: var(--boldtext);
}