.fieldLabel {
    display: flex;
    font-size: 120%;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: var(--boldtext);
    /* gap: 5px; */
}

.smallTextInput {
    font-family: 'Roboto', sans-serif;
    border: 2px solid gray;
    padding: 5px;
    margin-right: 10px;
    background-color: var(--background);
    font-size: 16px;
}

.smallTextInput {
    border-radius: 20px;
}

.addButton {
    background-color: #3dc45a;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    border: 0px;
    margin: 5px 10px 5px 0px;
    cursor: pointer;
}

.addButton:disabled {
    background-color: var(--gray);
    cursor: default;
}

.removeButton {
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
    background-color: transparent;
    width: 1.5em;
    height: 1.5em;
    background-image: url("../../assets/X.png");
    background-position: 50% 50%;
    background-size: 2em;
    background-repeat: no-repeat;
}

.suggestionList {
    position: absolute;
    margin-top: 55px;
    background: var(--background);
    border: 1px solid #ccc;
    width: 200px;
    list-style-type: none;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.suggestionItem {
    padding: 8px;
    cursor: pointer;
}

.suggestionItem:hover {
    background: #f0f0f0;
}

.tag {
    color:white;
    background-color: var(--themeColor);
    border-radius: 15px;
    padding: 0.4em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    width: fit-content;
    display: flex;
    flex-direct: row;
    gap: 0.5em;
    align-items: center;
}

.tags {
    display: flex;
    flex-direct: row;
    flex-wrap: wrap;
    gap:10px;
    margin-bottom: 1em;
    margin-top: 0.5em;
}