.select select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  color: var(--text);

  z-index: 1;

  &::-ms-expand {
    display: none;
  }
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  width: 98%;

  border: 1px solid var(--themeColor);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 15px;
  cursor: pointer;
  line-height: 1.1;

  background-color: var(--background);
  background-image: linear-gradient(to top, var(--background), var(--background) 33%);
  color: var(--text);

  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--themeColor);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}