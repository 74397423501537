.userHeader {
    border-radius: 15px;
    border-style: solid;
    border-color: var(--outline);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1.5em;
}

.profilePicture {
    width: 150px;
    height: 150px;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
}

.imageContainer {
    width: 150px;
    height: 150px;
}

.imageButtons {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
    max-width: 8em;
    margin: 1em;
}

.imageSettings {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.imageButtons button {
    background-color: var(--gray);
    border: none;
    border-radius: 5px;
    padding: 1em;
}

.imageButtons button:hover {
    background-color: var(--accentColor);
    color: white;
}

.h1 {
    font-size: 48px;
    text-align: left;
    color: black;
    line-height: 50px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.h2,
.h3 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: black;
    font-weight: normal;
}

.h2 {
    font-size: 24px;
}

.userPageContent {
    padding: 20px;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.nameEntry {
    width: 100%;
    height: 2rem;
    font-size: 125%;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 5px;
    padding-left: 5px;
}

.stacked {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-grow: 1;
}

.fieldLabel{
    font-weight: bold;
    font-size: 1.5em;
}

.descriptionEntry {
    width: 100%;
    max-width: 90vw;
    min-height: 8rem;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    border-radius: 10px;
    margin-top: 0.5em;
    font-size: 16px;
}

.tagEntry>input {
    margin-bottom: 1em;
    margin-right: 1em;
}

.deleteSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2em;
    width: 100%;
    margin-top: 5em;
    margin-bottom: 2em;
}

.deleteCheck {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    width: 65%;
    align-items: center;
}

.deleteCheck:hover {
    color: var(--red);
}

.deleteButton {
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    flex-grow: 1;
    font-size: 1em;
    background-color: var(--red);
    color: white;
}

.deleteButton:hover {
    background-color: var(--redDark);
    cursor: pointer;
    color: white;
}

.deleteButton:disabled {
    border-style: solid;
    border-color: var(--red);
    color: var(--red);
    background-color: var(--background);
    cursor: default;
}

.submitButton {
    background-color: var(--accentColor);
    color: white;
}

.submitButton:hover {
    background-color: var(--accentColorDark);
    cursor: pointer;
}

.cancelButton:hover {
    background-color: var(--red);
    cursor: pointer;
    color: white;
}

.deleteUserSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2em;
}

.formButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.formButtons button {
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    flex-grow: 1;
    font-size: 1em;
    margin: 5px 5px 5px 0px;
}

.stacked select {
    font-size: 1.5em;
}

.formBody {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.tagSelection label {
    font-weight: bold;
    font-size: 1.5em;
}

.tagSelection {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    margin-bottom: 1em;
}

.deleteSection input[type=checkbox] {
    transform: scale(1.5);
    margin: 0.5em;
    min-width: 15px;
}