.userHeader {
    position: relative;
    width: 96vw;  
    margin-left: calc(-48vw + 50%); 
    text-align: center;
}

.projectPhoto{
    width: 90%;
    height: 250px; 
    object-fit: cover;
    border-radius: 15px;
    margin: 1em;
}

.profilePicture {
    position: absolute;
    bottom: -1em;
    left: calc(10vw + 75px);
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid var(--background);
    align-self: center;
    object-fit: cover;
}

.imageButtonWrapper{
    position: absolute;
    top: 17px;
    right: 90px;
    display: flex;
    /* gap: 10px; */
    justify-content: flex-start; /* Or center/right as needed */
    align-items: center;

}

.changeImageButton {
    background-color: rgba(0, 0, 0, 0.432);
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  }
  
.changeImageButton:hover {
background-color: rgba(0, 0, 0, 0.9);
}

.infoSection{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between; /* Name on left, contact on right */
    align-items: center; /* Align items vertically */
    width: 100%; /* Ensure full width */
    margin-top: 2em;
}

.nameColumn {
    display: flex;
    flex-direction: column;
    /* gap: 1em; */
    margin-right: auto;
    max-width: 60%;
    min-width: 300px;
    flex: 1;
}

.contactColumn {
    display: flex;
    gap: 0.5em;
    flex-direction: column;
    text-align: right;
    justify-content: space-between;
    align-self: end;
    margin-top: 0.5em;
}

@media screen and (max-width: 600px) {
    .contactColumn {
        text-align: left;
    }
    .projectPhoto {
        width: 95vw;
        margin-left: 0;
    }
}

.h1 {
    font-size: 48px;
    text-align: left;
    color: var(--boldtext);
    line-height: 50px;
    margin-block-start: 0;
    margin-block-end: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.h2, .h3 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--boldtext);
    font-weight: normal;
}

.h2 {
    font-size: 1.17em;
}

.major {
    border: none;
    background-color: var(--background);
    width: fit-content;
    margin-top: 5px;
}

.pronouns{
    margin-top: 1em;
}

.userPageContent {
    padding-top: 1em;
}

.projects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
}

.projectCard {
    width: 17em;
    border: solid;
    border-width: 1.5px;
    border-color: var(--outline);
    border-radius: 5px;
    padding: 16px;
}

.projectCard a {
    font-weight: bold;
    font-size: 1.17em;
    color: var(--boldtext);
    text-decoration: none;
}
.projectCard a:hover {
    color: var(--themeColor);
}
.projectCard > p {
    margin: 0.5em;
    margin-left: 0;
}

.status {
    margin-top: 5px;
    font-size: 1em;
    display: flex;
    flex-direction: row;
}

.status > p {
    margin: 0.5em;
    align-self: center;
    font-weight: bold;
    font-size: 1.17em;
    color: var(--boldtext);
}

.status > svg {
    width: 30px;
    height: 24px;
    align-self: center;
    color: var(--boldtext);
}


.contactButton {
    color: white;
    background-color: var(--accentColor);
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    flex-grow: 1;
    font-size: 1em;
}

.contactButton:hover {
    background-color: var(--accentColorDark);
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1em;
}

.tag {
    color:white;
    background-color: var(--themeColor);
    border-radius: 15px;
    padding: 0.4em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    width: fit-content;
}

.popup {
    border-radius: 5px;
    padding: 3em;
    border-style: solid;
    border-color: var(--outline);
    background: var(--background);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.popup p {
    margin-top: 0;
}

.popupTextInput {
    width: 95%;
    height: 4rem;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 0.5em;
    outline: none;
}

.editButton {
    background-color: var(--accentColor);
    cursor: pointer;
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    font-size: 1em;
    color: white;
}

.editButton:hover {
    background-color: var(--accentColorDark);
    cursor: pointer;
}

.buttonsRow {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 0.5em;
}

.sendInviteButton {
    background-color: var(--accentColor);
    cursor: pointer;
    color: white;
    border-radius: 5px;
    padding: 15px;
    box-sizing: content-box;
    border-style: none;
    font-size: 1em;
}

.selectRow {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
    font-family: Roboto;
    margin-bottom: 1em;
}

.selectSpace {
    min-width: 40%;
    max-width: 50%;
}