.chatPage {
    display: flex;
    flex-direction: row;
}

.messageBox p {
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 150%;
}

.myMessageBox p {
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 150%;
}

.chatListColumn {
    border-right-style: solid;
    border-right-width: 2px;
    border-right-color: var(--outline);
    min-width: 22vw;
    max-width: 22vw;
    height: 75vh;
    overflow-y: scroll;
}

.chatListWideContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%
}

.chatListWide {
    margin-top: 1em;
    min-width: 500px;
    width: 55vw;
    max-width: 55vw;
    align-self: center;
    min-height: 100%;
}



@media screen and (max-width: 1000px) {
    .chatListColumn {
        display: none;
    }
}

.chatColumn {
    width: 95%;
    height: 75vh;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;
    flex-direction: column;
    display: flex;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
    overflow-y: scroll;
    margin: 1em;
    padding-right: 1em;
    margin-right: 0;
    height: 55vh;
}

.messageBox {
    border-radius: 1em;
    border-style: none;
    padding: 0.7em;
    background-color: var(--gray);
    width: fit-content;
    max-width: 100%;
}

.message {
    max-width: 40%;
    align-self: start;
}

.lowerText {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.8em;
    font-size: 10px;
}

.lowerText p {
    margin-top: 0.5em;
    margin-bottom: 0;
}

.timestamp {
    font-size: 12px;
    color: var(--text);
    margin-top: 0.5em;
    margin-bottom: 0;
}

.myMessage {
    max-width: 40%;
    align-self: end;
    display: flex;
    flex-direction: column;
    align-items: end;
}

.myMessageBox {
    border-radius: 1em;
    border-style: solid;
    border-color: var(--outline);
    border-width: 2px;
    padding: 0.7em;
    width: fit-content;
    max-width: 100%;
}

.myMessage .timeStamp {
    align-text: right;
}

.messageInput {
    width: 80%;
    border-radius: 5px;
    padding: 0.5em;
    text-align: top;
    border-style: solid;
    border-width: 1px;
    border-color: var(--outline);
    resize: none;
    height: 5em;
    flex-grow: 0.8;
    outline: none;
    font-size: 16px;
}

.messageInputArea {
    width: 100%-1em;
    margin-left: 1em;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: start;
    background: var(--background);
}

.topContent {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1em;
    justify-content: flex-start;
}

.userPhoto {
    width: 30px;
    height: 30px;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
    align-self: flex-end;
}

.chatHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: var(--outline);
    padding-left: 1em;
    gap: 1em;
    width: 100%;
}

.chatName {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.chatHeader h1 {
    color: var(--boldtext);
    margin-right: auto;
}

.deleteButton {
    border-radius: 5px;
    padding: 0.5em;
    box-sizing: content-box;
    border-style: solid;
    font-size: 1em;
    border-color: var(--red);
    color: var(--red);
    background-color: var(--background);
    margin-left: auto;
    margin-right: 2em;
}

.deleteButton:hover {
    background-color: var(--red);
    cursor: pointer;
    color: white;
}

.chatPreview {
    padding: 0.5em;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: var(--outline);
    min-height: 6em;
}

.userPreviewPhoto {
    width: 40px;
    height: 40px;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
}

.backButton {
    color: var(--text);
}

.userPreviewPhotoMedium {
    min-width: 3em;
    max-width: 3em;
    height: 3em;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.userPreviewPhotoLarge {
    min-width: 6em;
    max-width: 6em;
    height: 6em;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
    margin: 1em;
}

.groupPhoto {
    width: 60px;
    height: 40px;
    align-self: center;
    object-fit: cover;
    border-radius: 15%;
}

.groupPhotoMedium {
    width: 4.5em;
    height: 3em;
    align-self: center;
    object-fit: cover;
    border-radius: 15%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.groupPhotoLarge {
    width: 6em;
    height: 4em;
    align-self: center;
    object-fit: cover;
    border-radius: 15%;
    margin: 1em;
}

.chatInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.chatInfo p {
    margin: 0;
}

.chatInfo a {
    font-size: 20px;
}

.previewTime {
    color: var(--text);
    margin-left: auto;
    font-weight: normal;
}

.chatPreview p {
    color: var(--text);
    font-size: 16px;
    font-weight: normal;
}

.chatPreview h3 {
    margin: 0;
}


a:hover > div {
    background-color: var(--gray);
}

.currentChat {
    width: 0.5em;
    background-color: var(--themeColor);
    color: var(--themeColor);
}

.chatPreviewContainer {
    display: flex;
    flex-direction: row;
}

.noChats {
    display: flex;
    justify-content: center;
}

.noChats p {
    margin-top: 10em;
    color: var(--boldtext);
}

.sendButton {
    border-radius: 5px;
    padding: 0.5em;
    box-sizing: content-box;
    font-size: 1em;
    background-color: var(--accentColor);
    color: white;
    width: auto;
    border-style: none;
    flex-grow: 0.3;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
}

.sendButton:hover {
    border-radius: 5px;
    padding: 0.5em;
    box-sizing: content-box;
    font-size: 1em;
    border-style: none;
    background-color: var(--accentColorDark);
}

@media screen and (max-width: 600px) {
    .chatListWide {
        width: 95vw;
        min-width: 95vw;
    }
    .userPreviewPhotoLarge {
        min-width: 4.5em;
        max-width: 4.5em;
        height: 4.5em;
        margin: 0.5em;
    }
    .groupPhotoLarge {
        width: 4.5em;
        height: 3em;
        margin: 0.5em;
    }
    .message, .myMessage {
        max-width: 80%;
    }
}